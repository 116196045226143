<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title class="ma-2">Template</v-card-title>
        <v-form class="mx-5" ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="template.name"
                type="text"
                label="Name"
                :rules="[rule.required]"
                outlined
              />
              <v-text-field
                v-if="isAdminUser"
                v-model="template.view"
                class="mb-3"
                type="text"
                label="View"
                :rules="[rule.required]"
                outlined
              />
              <v-select
                :disabled="!isAdminUser"
                v-model="template.partials"
                item-text="name"
                item-value="id"
                :items="templates"
                :reduce="o => o.id"
                label="Partials"
                :multiple="true"
                outlined
                small-chips
                deletable-chips
              />
              <v-text-field
                v-if="template.is_page"
                v-model="template.site_title"
                class="mb-3"
                type="text"
                label="Site title"
                outlined
              />
              <v-text-field
                v-if="template.is_page"
                v-model="template.meta_description"
                class="mb-3"
                type="text"
                label="Meta description"
                outlined
              />
              <v-text-field
                v-if="template.is_page"
                v-model="template.meta_keywords"
                class="mb-3"
                type="text"
                label="Meta keywords"
                outlined
              />
              <div class="d-flex justify-space-between">
                <div class="d-flex">
                  <v-switch
                    v-model="template.is_page"
                    :disabled="!isAdminUser"
                    label="Page"
                    outlined
                  />
                  <v-switch
                    class="ml-5"
                    v-model="template.custom"
                    :disabled="!isAdminUser"
                    label="Custom"
                    outlined
                  />
                </div>
                <v-checkbox
                  v-if="!template.is_page"
                  outlined
                  class="ml-5 pull-right"
                  label="Use to all pages"
                  v-model="syncToAllPages"
                />
              </div>
              <v-divider v-if="fields.length"></v-divider>
              <div class="grey lighten-5 rounded mb-4 px-4">
                <template v-for="(field, i) in fields">
                  <div v-bind:key="i" class="mt-4">
                    <v-row
                      class="px-3 mb-2 pt-2"
                      justify="space-between"
                      align="center"
                    >
                      <div class="subtitle-1">Data #{{ i + 1 }}</div>
                      <v-btn color="danger" icon @click="removeField(i)">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-row>
                    <v-text-field
                      :disabled="!isAdminUser"
                      v-model="fields[i].name"
                      type="text"
                      label="Name"
                      :rules="[rule.required]"
                      outlined
                    />
                    <v-textarea
                      v-model="fields[i].value"
                      type="text"
                      label="Value"
                      :rules="[rule.required]"
                      outlined
                    />
                    <v-select
                      :disabled="!isAdminUser"
                      v-model="fields[i].lang"
                      :items="languages"
                      label="Language"
                      :rules="[rule.required]"
                      outlined
                    />
                    <v-divider></v-divider>
                  </div>
                </template>
                <div v-if="isAdminUser">
                  <v-btn
                    class="mb-4 mt-3"
                    color="secondary"
                    @click="addField"
                    text
                  >
                    <v-icon>mdi-plus</v-icon>
                    <span>Add data entry</span>
                  </v-btn>
                </div>
              </div>
              <v-divider></v-divider>
              <div class="float-right">
                <v-btn
                  color="primary"
                  class="mt-4"
                  :loading="getTemplateLoading"
                  @click="save()"
                >
                  {{ isCreate ? "Create template" : "Save" }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";

export default {
  metaInfo: {
    title: "Template"
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      syncToAllPages: false,
      templates: [],
      template: {
        name: null,
        view: null,
        is_page: false,
        custom: false,
        data: [],
        partials: []
      },
      fields: [],
      languages: ["nl", "en"]
    };
  },
  mounted() {
    this.page = this.$store.getters.getPage({ id: this.id });
    this.templates = this.$store.getters.getTemplatesExcluding(
      !this.isCreate ? this.id : ""
    );

    if (!this.isCreate) {
      this.template = _.cloneDeep(this.$store.getters.getTemplate(this.id));
      this.template.partials = _.map(
        this.template.partials,
        partial => partial.id
      );
      this.fields = _.get(this.template, "data");
    }
  },
  methods: {
    ...mapActions(["createTemplate", "updateTemplate"]),
    addField() {
      this.fields = [
        ...(this.fields || []),
        {
          name: null,
          value: null,
          lang: null
        }
      ];
    },
    removeField(index) {
      (this.fields || []).splice(index, 1);
    },
    async save() {
      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }
      const payload = {
        ...this.template,
        site_title: this.template.site_title ? this.template.site_title : null,
        meta_description: this.template.meta_description ? this.template.meta_description : null,
        meta_keywords: this.template.meta_keywords ? this.template.meta_keywords : null,
        data: this.fields,
        syncToAllPages: this.syncToAllPages
      };
      this.isCreate
        ? await this.createTemplate(payload)
        : await this.updateTemplate({ id: this.id, payload });
    }
  },
  computed: {
    ...mapGetters(["getTemplateLoading", "isAdminUser"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    }
  }
};
</script>
